import * as React from 'react';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';


const classes ={
    select: {

        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
            color: "purple"
        },
        "& .MuiInputLabel-root.Mui-focused": {
            color: "purple"
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "purple"
        }
    },

    icon: {
        fill: '#fff',
    },
};

export default function FilterStud() {
    const [age, setAge] = React.useState('');
   
    const handleChange = (event) => {
        setAge(event.target.value);
    };

    return (
        <Box>
            <Box style={{
                width: 380, height: 70, float: 'right'
                , marginRight: 40, display: 'grid',
                gridTemplateColumns: 'repeat(2, 1fr)',
                justifyContent: 'space-between'
            }}>

                <Box style={{ width: '100%', }}>

                    <Box style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)' }}>
                        <Typography style={{ color: '#fff', fontSize: 14, marginTop: 10 }}>
                            Sort by:
                        </Typography>
                        <Select
                            variant="outlined"
                            value={age}
                            onChange={handleChange}
                            style={{
                                backgroundColor: '#131414', border: '1px solid #303336',
                                borderRadius: 8, color: '#fff', width: 120, height: 40, fontSize: 14,
                                outline: 'none'
                            }}
                            className={classes.select}
                            inputProps={{
                                classes: {
                                    icon: classes.icon,
                                },
                            }}
                        >
                            <MenuItem value={10}>Asc</MenuItem>
                            <MenuItem value={20}>Desc</MenuItem>
                        </Select>
                    </Box>

                </Box>

                <Box style={{ width: '100%', paddingLeft: 20 }}>
                    <Box style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)' }}>
                        <Typography style={{ color: '#fff', fontSize: 14, marginTop: 10 }}>
                            Filter by:
                        </Typography>
                        <Select
                            variant="outlined"
                            value={age}
                            onChange={handleChange}
                            style={{
                                backgroundColor: '#131414', border: '1px solid #303336',
                                borderRadius: 8, color: '#fff', width: 120, height: 40, fontSize: 14,
                                outline: 'none'
                            }}
                            className={classes.select}
                            inputProps={{
                                classes: {
                                    icon: classes.icon,
                                },
                            }}
                        >
                            <MenuItem value={10}>Section</MenuItem>
                            <MenuItem value={20}>Reviews</MenuItem>
                            <MenuItem value={30}>Rating</MenuItem>
                        </Select>
                    </Box>
                </Box>
            </Box>

        </Box>
    );
}