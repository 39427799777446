import React, { useState } from 'react'
import { Box, AppBar, Toolbar, IconButton, Typography, InputBase, Grid, Link, Popper, Grow, Paper, MenuList } from '@mui/material'
import { styled, alpha } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import GridViewTwoToneIcon from '@mui/icons-material/GridViewTwoTone';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { FiTwitter, FiFacebook} from "react-icons/fi";
import {RiGoogleLine}from "react-icons/ri";

const Search = styled('div')(({ theme }) => ({
    display: 'block',
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.10),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.20),
    },
    marginLeft: theme.spacing(1),
    width: 'auto',

    [theme.breakpoints.down('sm')]: {
        flexGrow: 1,
        display: 'flex',
        alignSelf: 'flex-end',
        width: '88vw',
        height: '5vh',
        top: '55px',
        position: 'absolute',
    }
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
}));

const classes = {
    appbar: {
        position: "static",
        height: { sm: 'auto', xs: '27vw' },
        backgroundColor: (theme) => theme.palette.background.default,
    },
    navContainer: {
        display: {lg: 'flex', xs: 'none'},
        flexDirection: 'column',
        alignItems: 'center'
    },
    navLink: {
        fontSize: {
            xs:'12px',
            md:'18px',
            lg:'1rem'
        },
        color: 'white',
        padding: '1rem',
        position: 'relative',
        cursor: 'pointer',
        "&:hover, &:focus": {
            color: (theme) => theme.palette.secondary.main,
        }
    },
    hoverLine: {
        position: 'absolute',
        border: '2px solid',
        marginTop: '8px',
        borderRadius: '3px',
        justifyContent: 'center',
        alignItems: 'center',
        width: '20px',
        borderColor: (theme) => theme.palette.secondary.main,
    },
    cont:{
        color: (theme) => theme.palette.primary.text,
        fontFamily: (theme) => theme.typography.fontFamily.Roboto,
        fontWeight: 300,
        fontSize: {
            xs:'12px',
            md:'15px',
            lg:'1rem'
        },
        fontStyle: 'normal'
    },
}
export default function Nav() {
    const [active, setActive] = useState("")
    const handleNext = (id) => {
        switch (id) {
            case 'studList':
                setActive('studList')
                break;
            case 'studEval':
                setActive('studEval')
                break;
            case 'blog':
                setActive('blog')
                break;
            default:
                setActive('')
                break;
        }
    };
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
            
            const handleToggle = () => {
            setOpen((prevOpen) => !prevOpen);
            };
            
            const handleClose = (event) => {
            if (anchorRef.current && anchorRef.current.contains(event.target)) {
                    return;
                }
            
                setOpen(false);
                };
            
                function handleListKeyDown(event) {
                if (event.key === 'Tab') {
                    event.preventDefault();
                    setOpen(false);
                } else if (event.key === 'Escape') {
                    setOpen(false);
                }
                }
            
                // return focus to the button when we transitioned from !open -> open
                const prevOpen = React.useRef(open);
                React.useEffect(() => {
                if (prevOpen.current === true && open === false) {
                    anchorRef.current.focus();
                }
                prevOpen.current = open;
                }, [open]);
    return (
        <Box >
            <AppBar sx={classes.appbar}>
                <Toolbar>
                    <Grid sx={{ flexGrow: 1 }}>
                        <Typography
                            component="div"
                            sx={{ fontSize:  {
                                xs:'12px',
                                md:'18px',
                                lg:'1rem'
                            }, color: (theme) => theme.palette.primary.main, }}
                        >
                            Student Review
                            <IconButton
                                size="medium"
                                edge="end"
                                color="inherit"
                                aria-label="open drawer"
                                sx={{ margin: 2 }}
                            >
                                <GridViewTwoToneIcon sx={{ fontSize: 24 }} />
                            </IconButton>
                        </Typography>
                    </Grid >
                    <Grid sx={{ flexGrow: 1, display: 'flex', flexDirection: 'row' }}>
                        <Grid sx={classes.navContainer}>
                            {active === 'studList' ? <Box component='span' sx={classes.hoverLine} /> : ""}
                            <Link a href='#' underline='none' sx={classes.navLink} onClick={() => handleNext('studList')}>
                                Student List
                            </Link>
                        </Grid>
                        <Grid sx={classes.navContainer}>
                            {active === 'studEval' ? <Box component='span' sx={classes.hoverLine} /> : ""}
                            <Link a href='#' underline='none' sx={classes.navLink} onClick={() => handleNext('studEval')}>
                                Student Evaluation
                            </Link>
                        </Grid>
                        <Grid sx={classes.navContainer}>
                            {active === 'blog' ? <Box component='span' sx={classes.hoverLine} /> : ""}
                            <Link a href='#' underline='none' sx={classes.navLink} onClick={() => handleNext('blog')}>
                                Blog
                            </Link>
                        </Grid>
                    </Grid>

                    <IconButton  ref={anchorRef}
                                id="composition-button"
                                aria-controls={open ? 'composition-menu' : undefined}
                                aria-expanded={open ? 'true' : undefined}
                                aria-haspopup="true"
                                onClick={handleToggle}
                        >
                            <AccountCircleOutlinedIcon  sx={{ color: (theme) => theme.palette.primary.main, }}/>
                        </IconButton>
              <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-start"
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom-start' ? 'left top' : 'left bottom',
                  opacity: .9,
              }}
            >
                <Paper elevation={3} sx={{
                      display: 'flex',
                      justifyContent : 'center',
                      alignItems: 'center',
                      flexDirection:'column',
                   
                      border: 1,
                      backgroundColor: (theme) => theme.palette.background.default,
                    '& > :not(style)': {
                      m: 2,
                      p: 2,
                      width: 180,
                      height: 150,
                      
                    },
                  }}>
         
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}
                  >
                      <Box>
                      <Box sx={{    
                          display:'flex',
                          justifyContent : 'center',
                            alignItems: 'center',
                            flexDirection:'column',}}>
                 
                 <Typography sx={{...classes.cont,  fontSize: '18px',alignItems:'center'}}> Sign in </Typography>
                            <Typography sx={classes.cont}> Sign in to review and rate</Typography>
                            <Typography sx={classes.cont}> Students</Typography>
             </Box>
             <Box sx={{ display:'flex',
                          justifyContent : 'center',
                            alignItems: 'center',
                            flexDirection:'row',
                            mt: 3}}>
                        <FiFacebook  style={{margin: 5, fontSize: 20, backgroundColor:'#4267B2', padding: 5}}
                  color="#fff"/>
                <FiTwitter style={{margin: 5, fontSize: 20, backgroundColor:'#00ACEE', padding: 5 }}
                 color="#fff"/>
                <RiGoogleLine  style={{margin: 5, fontSize: 20, backgroundColor:'#4285F4', padding: 5}}
                  color="#fff"/></Box>
                </Box>
                   
                  </MenuList>
                </ClickAwayListener>
  
              </Paper>
            </Grow>
          )}
        </Popper>
      
                    
                    <Search>
                        <SearchIconWrapper>
                            <SearchIcon />
                        </SearchIconWrapper>
                        <StyledInputBase
                            inputProps={{ 'aria-label': 'search' }}
                        />
                    </Search>
                </Toolbar>
            </AppBar>
        </Box >
    )
}