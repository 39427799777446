import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import StarIcon from '@mui/icons-material/Star';
import image from "../assets/images/Em.jpg"

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#131414',
        color: '#7C7E83',
        border: 'none',
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 12,
        color: '#D1D4C9',
        border: 'none',
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // '&:nth-of-type(odd)': {
    backgroundColor: '#1E1F20',
    height: 18,
    borderRadius: 15,
    marginTop: 100,

    // hide last border
    // '&:last-child td, &:last-child th': {
    //     border: 0,
    // },
}));

function createData(name, yrSection, reviews, rating) {
    return { name, yrSection, reviews, rating };
}

const rows = [
    createData('Student Name 1', 'BSIT4A', 30, 24),
    createData('Student Name 2', 'BSIT4A', 30, 24),
    createData('Student Name 3', 'BSIT4A', 30, 24),
    createData('Student Name 4', 'BSIT4A', 30, 24),
    createData('Student Name 5', 'BSIT4A', 30, 24),
];

export default function StudTable() {
    return (
        <TableContainer style={{
            marginTop: -50,
            width: '100%',

        }}>
            <Table sx={{
                minWidth: 700,
            }} style={{
                borderCollapse: 'separate',
                borderSpacing: '0 .7em',
            }}>
                <TableHead>
                    <TableRow>
                        <StyledTableCell align="left" style={{ width: 270 }}>Name</StyledTableCell>
                        <StyledTableCell align="center">Year & Section</StyledTableCell>
                        <StyledTableCell align="center">Reviews</StyledTableCell>
                        <StyledTableCell align="center" style={{ width: 100 }}>Rating</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody style={{ width: '100%', }}>
                    {rows.map((row) => (
                        <StyledTableRow key={row.name} style={{cursor:'pointer'}}>
                            <StyledTableCell align="left" style={{
                                borderTopLeftRadius: 15, borderBottomLeftRadius: 15,
                                border: '1px solid #303336', borderRight: 'none'
                            }}>
                                <Box style={{
                                    display: 'flex', flexDirection: 'row',
                                    justifyContent: 'flex-start', alignItems: 'center',
                                }}>
                                    <Typography style={{ width: 25, fontSize: 12 }}>
                                        1
                                    </Typography>
                                    <Box style={{ width: 45 }}>
                                        <CardMedia
                                            component="img"
                                            sx={{ width: 35, height: 35, }}
                                            src={image}
                                        />
                                    </Box>
                                    <Typography style={{ fontSize: 14 }}>
                                        {row.name}
                                    </Typography>
                                </Box>

                            </StyledTableCell>
                            <StyledTableCell align="center" style={{
                                border: '1px solid #303336',
                                borderLeft: 'none', borderRight: 'none'
                            }}>{row.yrSection}</StyledTableCell>
                            <StyledTableCell align="center" style={{
                                border: '1px solid #303336',
                                borderLeft: 'none', borderRight: 'none'
                            }}>{row.reviews}</StyledTableCell>
                            <StyledTableCell align="center" style={{
                                borderTopRightRadius: 15, borderBottomRightRadius: 15,
                                border: '1px solid #303336', borderLeft: 'none',
                            }}>
                                <Box sx={{
                                    display: 'flex',
                                    gridTemplateColumns: 'repeat(5, 1fr)',
                                }}>
                                    <StarIcon style={{ color: '#26CE8D', width: 20, height: 20 }} />
                                    <StarIcon style={{ color: '#26CE8D', width: 20, height: 20 }} />
                                    <StarIcon style={{ color: '#26CE8D', width: 20, height: 20 }} />
                                    <StarIcon style={{ color: '#26CE8D', width: 20, height: 20 }} />
                                    <StarIcon style={{ color: '#26CE8D', width: 20, height: 20 }} />
                                </Box>
                            </StyledTableCell>
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
