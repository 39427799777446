import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';


import StudTable from '../StudListComp/StudTable';
import FilterStud from '../StudListComp/FilterStud';
import StudentTable from '../StudListComp/StudentTable';
import Footer from '../MainCom/Footer';
import TopStud from '../StudListComp/TopStud';


export default function SearchAppBar() {
  return (
    <Box sx={{ flexGrow: 1, backgroundColor: '#131414', width: '100%' }} >
     

      {/* body */}
      <Box style={{ width: '70%', margin: 'auto', marginBottom: 120 }}>
        <Box>
          <Typography style={{ color: '#fff' }}>Top Students</Typography><br />
          <Box sx={{
            display: 'grid', gap: 1.5,
            gridTemplateColumns: 'repeat(4, 1fr)', width: '70%',
          }}>
          
          <TopStud />
          <TopStud />
          <TopStud />
          <TopStud />

          </Box>
        </Box>

        {/* filter component  */}
        <Box style={{ marginTop: 20, }}>
          <FilterStud />
        </Box>


        {/* table */}
        <Box style={{ marginTop: 80, }}>
          <StudTable />
          <Box style={{ textAlign: 'center', width: 300, margin: 'auto' }}>
            <StudentTable />
          </Box>
        </Box>
      </Box>

      <Box style={{
        width: '100%', height: 180, backgroundColor: '#131414',
        borderTop: '1px solid #515456'
      }}>
        <Footer />
      </Box>
    </Box>
  );
}
