const theme =  {
    palette: {
      primary: {
        main: '#E5E5E5',
        text: '#6f6a6a'
      },
      secondary: {
        main: '#26CE8D',
      },
      error: {
        main: '#E03E65',
      },
      info: {
        main: '#4267B2',
        light: '#00ACEE',
        dark: '#4285F4',
      },
      background: {
        default: '#131414',
      },
    },
    typography: {
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
    },
  }

export default theme;