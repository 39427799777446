import React from 'react'
import { Card, Grid, Link, Typography, Button } from '@mui/material';
import { FiTwitter, FiFacebook } from "react-icons/fi";
import { RiDiscordLine } from "react-icons/ri";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const classes = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignSelf: 'flex-end',
        backgroundColor: (theme) => theme.palette.background.default,
        marginBottom: 5,
    },
    text: {
        textAlign: 'center',
        fontSize: {
            xs:'12px',
            md:'15px',
            lg:'1rem'
        },
        margin: 1,
        color: '#62666D'
    },
    icons: {
        margin: 5,
        fontSize: 22
    },
    link:{
        color: (theme) => theme.palette.secondary.main
    }
}

export default function Footer() {
    return (
        <Grid sx={classes.container}>
            <Card sx={{ height: '180px', padding: 5,backgroundColor: (theme) => theme.palette.background.default,}}>
                <Grid sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    margin: 3
                }}>
                    <FiTwitter style={classes.icons} color="#26CE8D" />
                    <RiDiscordLine style={classes.icons} color="#26CE8D" />
                    <FiFacebook style={classes.icons} color="#26CE8D" />
                </Grid>
                <Typography sx={classes.text}>
                    Contact us: <Link underline="none" sx={classes.link} a href='#'>support@studentreview.com</Link>
                </Typography>

                <Typography sx={classes.text}>
                    &copy; 2021 Student Review. All Rights Reserved.
                </Typography>
                <Typography sx={classes.text}>
                    <Link underline="none" sx={classes.link}>Terms of Service</Link>
                    &ensp;|	&ensp;
                    <Link underline="none" sx={classes.link}>Privacy Policy</Link>
                </Typography>
                <Button variant='contained'
                    sx={{
                        borderRadius: '50px',
                        height:'20px',
                        width: '80px',
                        bottom: 50,
                        right: 50,
                        top: -5,
                        float: 'right',
                        textTransform: 'none',
                        backgroundColor:"#26CE8D",
                        color: 'white'
                    }}>
                    <HelpOutlineIcon sx={{ fontSize: 15, marginRight: 1 }} />
                    Help
                </Button>
            </Card>
        </Grid>
    )
}