import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import StarIcon from '@mui/icons-material/Star';
import { Avatar, Rating, Stack } from '@mui/material';
import image from "../assets/images/Em.jpg"
export default function TopStud() {

    return (
        <Card sx={{
            display: 'flex', width: 210, height: 110,
            backgroundColor: '#1E1F20', border: '1px solid #303336',
            boxSizing: 'border-box',
            boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.4)',
            borderRadius: 2
        }}>
          
              <CardMedia
                       component="img"
                        sx={{ width: 38, height: 38,margin: 2, borderRadius: 1 }}
                        src={image}
                      />
                                   
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <CardContent sx={{ flex: '1 0 auto' }}>
                    <Typography component="div" style={{ fontSize: 14, color: '#fff' }}>
                      Student Name
                    </Typography>
                    <Typography style={{ color: '#7C7E83', fontSize: 12 }} component="div">
                        30 reviews
                    </Typography>
                </CardContent>
                <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>
                    <Box sx={{
                        display: 'flex',
                        gap: .1,
                        gridTemplateColumns: 'repeat(5, 1fr)',
                    }}>
               <Rating style={{ color: '#26CE8D'}} defaultValue={2}  />
   
                    </Box>
                </Box>
            </Box>

        </Card>
    );
}
