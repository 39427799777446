import React from 'react'
//theme
import { ThemeProvider, createTheme } from '@mui/material'
import theme from './utils/theme'
//router
import { Routes, Route, Navigate } from 'react-router-dom'
import Landingpage from "./pages/Landingpage"

export default function App() {
  const THEME = createTheme(theme);
  return (
    <ThemeProvider theme={THEME}>
      <Routes>
        <Route path="/" element={<Navigate to="main" />} />
        <Route path="main" element={<Landingpage />} />
      </Routes>
    </ThemeProvider>
  )

}