import { Box } from '@mui/system';
import React from 'react'
import Nav from '../MainCom/Nav';
import TopStud from './TopStud';

export default function Header() {

            
                return (
                    <Box sx={{backgroundColor: (theme) => theme.palette.background.default,}}> 
            <Nav/>
            <TopStud/>
          
        </Box >
      
    )
}